import * as React from 'react'
import * as footerStyle from '../styles/footer.module.scss'
import scrollTo from 'gatsby-plugin-smoothscroll';



const SiteFooter = ({ children }) => {
    return (
      <footer>
        <div className={footerStyle.footerContainer}>

        <div className={footerStyle.footerInfoContainer} id="contact">
        <span><h2 className={footerStyle.footerHeading}>Contact</h2></span>
            <span>GlobalTel, a.s.<br />Landererova 12<br />811 09 Bratislava</span>
            <span>Tel: +421-(0)2-35-000-654<br />e-mail: info@globaltel.sk</span>
            <span>IČO 35778199<br />DIČ 2020255677<br />IČ DPH SK2020255677<br />IBAN SK4111000000002622106773</span>
        </div>
        <div className={footerStyle.copyright}>
        <span>© Globaltel a.s. 2022</span>
        </div>
        </div>
    </footer>
    )
  }
  
  export default SiteFooter