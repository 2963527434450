import * as React from 'react'
import { useState } from 'react';
import {  Link } from 'gatsby'
import scrollTo from 'gatsby-plugin-smoothscroll';
import * as navHeaderStyle from '../styles/navHeader.module.scss'



const NavHeader = ({ children }) => {

  const [toggleNav, setToggleNav]=useState(false)

  const [open, setOpen] = useState(false);

  const mobileNavButton = open ? "toClose" : "toOpen";

  const showMenu=()=> setToggleNav(!toggleNav);


    return (
      <header>
          <div className={navHeaderStyle.headerContainer}>
          <Link to="/en">
              <div className={navHeaderStyle.logoElement}></div>
              </Link>
              <nav>

                    <Link activeClassName={navHeaderStyle.active} partiallyActive={true} to="/en/news">News</Link>
                    <Link activeClassName={navHeaderStyle.active} partiallyActive={true} to="/en/eGovernment">eGovernment</Link>
                    <Link activeClassName={navHeaderStyle.active} partiallyActive={true} to="/en/projects">Projects</Link>
                    <Link activeClassName={navHeaderStyle.active} partiallyActive={true} to="/en/about-us">About us</Link>
                    <a onClick={() => scrollTo('#contact')}>Contact us</a>
                    <Link activeClassName={navHeaderStyle.active} partiallyActive={true} to="../../">SK</Link>
              </nav>
                    <div id={navHeaderStyle.mobileNavButtonId} className={mobileNavButton} onClick={() => {setOpen(!open);showMenu()}}></div>
              
              
          </div>
          {toggleNav && <div className={navHeaderStyle.mobileNavContainer}>
                    <nav className={navHeaderStyle.mobileNav}>
                    <Link activeClassName={navHeaderStyle.active} partiallyActive={true} to="/en/news">News</Link>
                    <Link activeClassName={navHeaderStyle.active} partiallyActive={true} to="/en/eGovernment">eGovernment</Link>
                    <Link activeClassName={navHeaderStyle.active} partiallyActive={true} to="/en/projects">Projects</Link>
                    <Link activeClassName={navHeaderStyle.active} partiallyActive={true} to="/en/about-us">About us</Link>
                    <a onClick={() => scrollTo('#contact')}>Contact us</a>
                    <Link activeClassName={navHeaderStyle.active} partiallyActive={true} to="../../">SK</Link>
                    </nav>
            </div>}

              
      </header>
    )
  }
  
  export default NavHeader