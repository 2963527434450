// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-e-government-egovernment-dotacie-js": () => import("./../../../src/pages/eGovernment/egovernment-dotacie.js" /* webpackChunkName: "component---src-pages-e-government-egovernment-dotacie-js" */),
  "component---src-pages-e-government-egovernment-doveryhodne-dorucovanie-js": () => import("./../../../src/pages/eGovernment/egovernment-doveryhodne-dorucovanie.js" /* webpackChunkName: "component---src-pages-e-government-egovernment-doveryhodne-dorucovanie-js" */),
  "component---src-pages-e-government-egovernment-platobny-system-js": () => import("./../../../src/pages/eGovernment/egovernment-platobny-system.js" /* webpackChunkName: "component---src-pages-e-government-egovernment-platobny-system-js" */),
  "component---src-pages-e-government-js": () => import("./../../../src/pages/eGovernment.js" /* webpackChunkName: "component---src-pages-e-government-js" */),
  "component---src-pages-en-404-js": () => import("./../../../src/pages/en/404.js" /* webpackChunkName: "component---src-pages-en-404-js" */),
  "component---src-pages-en-about-us-js": () => import("./../../../src/pages/en/about-us.js" /* webpackChunkName: "component---src-pages-en-about-us-js" */),
  "component---src-pages-en-e-government-egovernment-payment-system-js": () => import("./../../../src/pages/en/eGovernment/egovernment-payment-system.js" /* webpackChunkName: "component---src-pages-en-e-government-egovernment-payment-system-js" */),
  "component---src-pages-en-e-government-egovernment-public-grants-js": () => import("./../../../src/pages/en/eGovernment/egovernment-public-grants.js" /* webpackChunkName: "component---src-pages-en-e-government-egovernment-public-grants-js" */),
  "component---src-pages-en-e-government-egovernment-trustworthy-delivery-js": () => import("./../../../src/pages/en/eGovernment/egovernment-trustworthy-delivery.js" /* webpackChunkName: "component---src-pages-en-e-government-egovernment-trustworthy-delivery-js" */),
  "component---src-pages-en-e-government-js": () => import("./../../../src/pages/en/eGovernment.js" /* webpackChunkName: "component---src-pages-en-e-government-js" */),
  "component---src-pages-en-index-js": () => import("./../../../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-news-18-years-of-slovensko-sk-mdx": () => import("./../../../src/pages/en/news/18-years-of-slovensko-sk.mdx" /* webpackChunkName: "component---src-pages-en-news-18-years-of-slovensko-sk-mdx" */),
  "component---src-pages-en-news-inclusion-among-vmware-partners-mdx": () => import("./../../../src/pages/en/news/inclusion_among_vmware_partners.mdx" /* webpackChunkName: "component---src-pages-en-news-inclusion-among-vmware-partners-mdx" */),
  "component---src-pages-en-news-js": () => import("./../../../src/pages/en/news.js" /* webpackChunkName: "component---src-pages-en-news-js" */),
  "component---src-pages-en-projects-js": () => import("./../../../src/pages/en/projects.js" /* webpackChunkName: "component---src-pages-en-projects-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-o-spolocnosti-js": () => import("./../../../src/pages/o-spolocnosti.js" /* webpackChunkName: "component---src-pages-o-spolocnosti-js" */),
  "component---src-pages-projekty-js": () => import("./../../../src/pages/projekty.js" /* webpackChunkName: "component---src-pages-projekty-js" */),
  "component---src-pages-spravy-18-rokov-slovensko-sk-mdx": () => import("./../../../src/pages/spravy/18-rokov-slovensko-sk.mdx" /* webpackChunkName: "component---src-pages-spravy-18-rokov-slovensko-sk-mdx" */),
  "component---src-pages-spravy-elektronicke-hromadne-ziadosti-mdx": () => import("./../../../src/pages/spravy/elektronicke-hromadne-ziadosti.mdx" /* webpackChunkName: "component---src-pages-spravy-elektronicke-hromadne-ziadosti-mdx" */),
  "component---src-pages-spravy-js": () => import("./../../../src/pages/spravy.js" /* webpackChunkName: "component---src-pages-spravy-js" */),
  "component---src-pages-spravy-reakcia-firmy-globaltel-na-medializovane-informacie-o-projekte-muk-p-mdx": () => import("./../../../src/pages/spravy/reakcia-firmy-globaltel-na-medializovane-informacie-o-projekte-muk-p.mdx" /* webpackChunkName: "component---src-pages-spravy-reakcia-firmy-globaltel-na-medializovane-informacie-o-projekte-muk-p-mdx" */),
  "component---src-pages-spravy-system-pre-ekolky-otvara-nove-moznosti-mdx": () => import("./../../../src/pages/spravy/system-pre-ekolky-otvara-nove-moznosti.mdx" /* webpackChunkName: "component---src-pages-spravy-system-pre-ekolky-otvara-nove-moznosti-mdx" */),
  "component---src-pages-spravy-vyuzivanie-elektronickych-schranok-ma-rezervy-mdx": () => import("./../../../src/pages/spravy/vyuzivanie-elektronickych-schranok-ma-rezervy.mdx" /* webpackChunkName: "component---src-pages-spravy-vyuzivanie-elektronickych-schranok-ma-rezervy-mdx" */),
  "component---src-pages-spravy-zaradenie-medzi-vmware-partnerov-mdx": () => import("./../../../src/pages/spravy/zaradenie-medzi-vmware-partnerov.mdx" /* webpackChunkName: "component---src-pages-spravy-zaradenie-medzi-vmware-partnerov-mdx" */)
}

