import * as React from 'react'
import PropTypes from "prop-types"
import NavHeader from '../components/navHeaderEn'
import SiteFooter from '../components/footerEn'
import PageTitleNews from '../components/pageTitleNews'
import { Link } from 'gatsby'
import * as newsStyle from '../styles/news.module.scss'

const NewsLayoutEn = ({ children, h1 }) => {
    return (
      <>
        <NavHeader/>
        <PageTitleNews pageTitle="News"/>
        <main>
            <div className="mainContainer">
                <article>
                    <div className={newsStyle.articleHeading}>
                        <Link to="../"></Link>
                    </div>
                    <div className={newsStyle.articleQueryContent}>{children}</div>
                </article>
            </div>
        </main>
        <SiteFooter/>
      </>
    )
  }
  
  NewsLayoutEn.propTypes = {
    children: PropTypes.node.isRequired,
    h1: PropTypes.element,
  }

export default NewsLayoutEn


