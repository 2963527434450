import * as React from 'react'
import * as pageTitleNewsStyle from '../styles/pageTitleNews.module.scss'



const PageTitleNews = ({ pageTitle, children }) => {
    
    return (
      <div className={pageTitleNewsStyle.pageTitleNews}>
            <div className={pageTitleNewsStyle.pageTitleBgNews}>
                <div>
                    <h1>{pageTitle}</h1>
                </div>
            </div>
        </div>
    )
  }
  
  export default PageTitleNews